/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, SeparateLine, SeparateLineWrap, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Champions"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center pt--80" style={{"paddingBottom":0,"marginBottom":48}} name={"vl913cfl2w"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center lh--1" content={"<span style=\"font-style: italic;\">Vína</span>&nbsp; legend<br>kompletní nabídka<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--60" style={{"paddingBottom":37}} layout={"l9"} name={"p83fsecmfy"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/78a3e80270714ddb849fe9bed2207f58_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/78a3e80270714ddb849fe9bed2207f58_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/78a3e80270714ddb849fe9bed2207f58_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/78a3e80270714ddb849fe9bed2207f58_s=860x_.png 860w"} lightbox={false} use={"page"} href={"/amauri"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\">AMAURI</span>"}>
              </Title>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/eb9490bcba624e86bc16b4fdc78605da_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/eb9490bcba624e86bc16b4fdc78605da_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/eb9490bcba624e86bc16b4fdc78605da_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/eb9490bcba624e86bc16b4fdc78605da_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/eb9490bcba624e86bc16b4fdc78605da_s=1400x_.png 1400w"} lightbox={false} use={"page"} href={"/chevanton"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/chevanton\">CHEVANTON</a></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/32748ab0a1d0417f81c2edaa469c4e9c_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/32748ab0a1d0417f81c2edaa469c4e9c_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/32748ab0a1d0417f81c2edaa469c4e9c_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/32748ab0a1d0417f81c2edaa469c4e9c_s=860x_.png 860w"} lightbox={false} use={"page"} href={"/lugano"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/lugano\">LUGANO</a></span>"}>
              </Title>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/7d73501cdbd34b30a4e8d7286d8c6cbf_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/7d73501cdbd34b30a4e8d7286d8c6cbf_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/7d73501cdbd34b30a4e8d7286d8c6cbf_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/7d73501cdbd34b30a4e8d7286d8c6cbf_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/7d73501cdbd34b30a4e8d7286d8c6cbf_s=1400x_.png 1400w"} lightbox={false} use={"page"} href={"/candela"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/candela\">CANDELA</a></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/13786491c7cb4dd3ab0348d3feeb8f33_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/13786491c7cb4dd3ab0348d3feeb8f33_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/13786491c7cb4dd3ab0348d3feeb8f33_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/13786491c7cb4dd3ab0348d3feeb8f33_s=860x_.png 860w"} lightbox={false} use={"page"} href={"/kuranyi"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/lugano-Kopie-7tdtb3oe6i8\">KURANY</a></span>"}>
              </Title>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/9a0b0abdf40f4cdd84025fb6d87720cf_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/9a0b0abdf40f4cdd84025fb6d87720cf_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/9a0b0abdf40f4cdd84025fb6d87720cf_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/9a0b0abdf40f4cdd84025fb6d87720cf_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/9a0b0abdf40f4cdd84025fb6d87720cf_s=1400x_.png 1400w"} lightbox={false} use={"page"} href={"/jankulovski"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/jankulovski\">JANKULOVSKI</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":23,"paddingBottom":48}} layout={"l9"} name={"p83fsecmfy"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/f31ef683a22e4824b68eac518b7a5edb_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/f31ef683a22e4824b68eac518b7a5edb_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/f31ef683a22e4824b68eac518b7a5edb_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/f31ef683a22e4824b68eac518b7a5edb_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/f31ef683a22e4824b68eac518b7a5edb_s=1400x_.png 1400w"} lightbox={false} use={"page"} href={"/cesar"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/cesar\">CESAR</a></span>"}>
              </Title>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/7bb14202f6b14fea923c5fc238bbe9e7_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/7bb14202f6b14fea923c5fc238bbe9e7_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/7bb14202f6b14fea923c5fc238bbe9e7_s=660x_.png 660w"} lightbox={false} use={"page"} href={"/galliani"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/galliani\">GALLIANI</a></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/392a631c353a43f3b0f193c84712272c_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/392a631c353a43f3b0f193c84712272c_s=350x_.png 350w"} lightbox={false} use={"page"} href={"/ronaldinho"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/ronaldinho\">RONALDINHO</a></span>"}>
              </Title>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/d89f9b66112647a5bb8cc967760b9d6b_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/d89f9b66112647a5bb8cc967760b9d6b_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/d89f9b66112647a5bb8cc967760b9d6b_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/d89f9b66112647a5bb8cc967760b9d6b_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/d89f9b66112647a5bb8cc967760b9d6b_s=1400x_.png 1400w"} lightbox={false} use={"page"} href={"/materazzi"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/materazzi\">MATERAZZI</a></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/a26c14d403db449fa515067214ee6e71_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/a26c14d403db449fa515067214ee6e71_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/a26c14d403db449fa515067214ee6e71_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/a26c14d403db449fa515067214ee6e71_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/a26c14d403db449fa515067214ee6e71_s=1400x_.png 1400w"} lightbox={false} use={"page"} href={"/sneijder"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/sneijder\">SNEIJDER</a></span>"}>
              </Title>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/8905afdeb3d5415aa0d39bd63a0dfe57_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/8905afdeb3d5415aa0d39bd63a0dfe57_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/8905afdeb3d5415aa0d39bd63a0dfe57_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/8905afdeb3d5415aa0d39bd63a0dfe57_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/8905afdeb3d5415aa0d39bd63a0dfe57_s=1400x_.png 1400w"} lightbox={false} use={"page"} href={"/ochoa"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/ochoa\">OCHOA</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"paddingTop":7}} layout={"l9"} name={"p83fsecmfy"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/0d0e704fa213489abced19876ad369e9_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/0d0e704fa213489abced19876ad369e9_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/0d0e704fa213489abced19876ad369e9_s=660x_.png 660w"} lightbox={false} use={"page"} href={"/terry"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/terry\">TERRY</a></span>"}>
              </Title>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/b22ff392173c4927a6165d5861d168d0_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/b22ff392173c4927a6165d5861d168d0_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/b22ff392173c4927a6165d5861d168d0_s=660x_.png 660w"} lightbox={false} use={"page"} href={"/cafu"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/cafu\">CAFU</a></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/b862bc9ddd184336b8c574038c6a18ac_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/b862bc9ddd184336b8c574038c6a18ac_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/b862bc9ddd184336b8c574038c6a18ac_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/b862bc9ddd184336b8c574038c6a18ac_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/b862bc9ddd184336b8c574038c6a18ac_s=1400x_.png 1400w"} lightbox={false} use={"page"} href={"/zamorano"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/zamorano\">ZAMORANO</a></span>"}>
              </Title>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/80ded7f2df3b475f933e8133152f3e53_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/80ded7f2df3b475f933e8133152f3e53_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/80ded7f2df3b475f933e8133152f3e53_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/80ded7f2df3b475f933e8133152f3e53_s=860x_.png 860w"} lightbox={false} use={"page"} href={"/frey"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/frey\">FREY</a></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/081c0aea47374510bdd3c1387486565b_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/081c0aea47374510bdd3c1387486565b_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/081c0aea47374510bdd3c1387486565b_s=660x_.png 660w"} lightbox={false} use={"page"} href={"/amoroso"}>
              </Image>

              <Title className="title-box title-box--center fs--30 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/amoroso\">AMOROSO</a></span>"}>
              </Title>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40863/20ed0f6b294747b7a1ff12b51f58bff2_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":370}} srcSet={"https://cdn.swbpg.com/t/40863/20ed0f6b294747b7a1ff12b51f58bff2_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/20ed0f6b294747b7a1ff12b51f58bff2_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/20ed0f6b294747b7a1ff12b51f58bff2_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/20ed0f6b294747b7a1ff12b51f58bff2_s=1400x_.png 1400w"} lightbox={false} use={"page"} href={"/carlos"}>
              </Image>

              <Title className="title-box title-box--center fs--30 ls--02 lh--18 mt--06" content={"<span style=\"font-weight: bold;\"><a href=\"/carlos\">CARLOS</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"6ozq5b7vi86"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-blend--50);\">Děkujeme, že jste si vybrali právě nás.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box ff--1" content={"<span style=\"color: var(--color-dominant);\">The Wine<br><span style=\"font-style: italic;\">of the</span> Champions<br></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-blend--50);\">+420 797 811 2X0<br>info@vinalegend.com</span>"}>
              </Text>

              <Image style={{"maxWidth":24,"marginTop":13}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""} alt={"instagram"} src={"https://cdn.swbpg.com/o/40863/b29c23bfb6ac42fbb4d2db5a1aebd354.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/jankul18/"} url={"https://www.instagram.com/jankul18/"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}